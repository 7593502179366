<template>
    <div class="content">
        <div class="content-1">
            <h2 class="title-1">服务条款</h2>
            <!-- 特别提示 -->
            <h3 class="title-2">1.特别提示</h3>
            <div class="content-text1">
                1.1 ESP色彩管理系统（以下简称“本软件”）是由亿涂（苏州）互联网科技有限
                公司（以下简称“苏州亿涂”）独立开发，并面向企业用户提供产品导入及查询工具。
                “便捷安全”是本软件的服务宗旨。请您仔细阅读本软件服务条款（下称“本条款”），如
                果您同意本条款，那么您可以成为本软件的用户（下称“用户”），使用本软件提供给您
                的服务；如果您不同意本条款，那么请您立即停止使用本软件。本条款适用于任何本
                软件提供的服务。
            </div>
            <div class="content-text1">
                1.2 企业用户如需购买本软件，需在购买前与本公司联系，并与本公司协商签订书面的
                服务协议，具体服务内容及服务条款以双方另行签订的服务协议为准。
            </div>
            <!-- 注册 -->
            <h3 class="title-2">2.注册</h3>
            <div class="content-text1">
                2.1 暂不开放用户注册功能，企业用户须使用前联系本公司，由本公司为企业用户开通企业账号。
            </div>
            <!-- 账号和密码 -->
            <h3 class="title-2">3.账号和密码</h3>
            <div class="content-text1">
                3.1 企业账号开通后，则拥有本软件的一个企业管理员账号及相应密码。企业应自行妥善保管账号和密码，本软件不对用户账号和密码的保管承担任何责任。
            </div>
            <div class="content-text1">
                3.2 企业可通过企业管理员账号为企业员工开通员工账号。
            </div>
            <div class="content-text1">
                3.3 用户有权随时更改密码，若发现任何安全漏洞或非法使用用户账号的情况，应当立即通知本软件。若用户非法访问其他用户账号，则用户需承担一切因此产生的法律后果。
            </div>
            <div class="content-text1">
                3.4 用户知晓并同意在未经授权的情况下，不得使用其他用户的账号或密码，由此产生的一切损失由该用户自行承担。
            </div>
            <div class="content-text1">
                3.5 用户需保证提交的本软件账号为合法可达的邮箱和手机号，并授权本软件运营商向该邮箱、手机发送和本软件服务相关的信息和通知。
            </div>
            <!-- 账号和密码 -->
            <h3 class="title-2">3.账号和密码</h3>
            <div class="content-text1">
                3.1 企业账号开通后，则拥有本软件的一个企业管理员账号及相应密码。企业应自行妥善保管账号和密码，本软件不对用户账号和密码的保管承担任何责任。
            </div>
            <div class="content-text1">
                3.2 企业可通过企业管理员账号为企业员工开通员工账号。
            </div>
            <div class="content-text1">
                3.3 用户有权随时更改密码，若发现任何安全漏洞或非法使用用户账号的情况，应当立即通知本软件。若用户非法访问其他用户账号，则用户需承担一切因此产生的法律后果。
            </div>
            <div class="content-text1">
                3.4 用户知晓并同意在未经授权的情况下，不得使用其他用户的账号或密码，由此产生的一切损失由该用户自行承担。
            </div>
            <div class="content-text1">
                3.5 用户需保证提交的本软件账号为合法可达的邮箱和手机号，并授权本软件运营商向该邮箱、手机发送和本软件服务相关的信息和通知。
            </div>
            <!-- 使用规则 -->
            <h3 class="title-2">4.使用规则</h3>
            <div class="content-text1">
                4.1
                用户在使用本软件时，应当遵守相关的法律规则，包括但不限于《中华人民共和国宪法》、《中华人民共和国刑法》、《中华人民共和国民法通则》、《中华人民共和国合同法》、《中华人民共和国著作权法》、《中华人民共和国电信条例》、《互联网信息服务管理办法》、《互联网电子公告服务管理规定》、《中华人民共和国保守国家秘密法》、《中华人民共和国计算机信息系统安全保护条例》、《计算机信息网络国际联网安全保护管理办法》、《全国人民代表大会常务委员会关于维护互联网安全的决定》及其相关的司法解释。
            </div>
            <div class="content-text1">
                4.2 用户在使用本软件的过程中，必须遵循以下原则：
                <div>
                    (1) 遵守所有与本网络服务有关的网络协议、规定和程序；
                </div>
                <div> (2) 不得利用本软件进行任何可能对互联网安全健康环境运转造成不利影响的行为；
                </div>
                <div> (3) 不得利用本软件进行任何不利于本软件的行为；
                </div>
                <div> (4) 不得利用本软件传输任何骚扰性的、中伤他人的、辱骂性的、恐吓性的、庸俗淫秽的或其他任何非法的信息资料；
                </div>
                <div> (5 )不得试图利用技术或非技术手段, 未经本软件授权, 访问本软件中其他用户账号中的信息资料。
                </div>
            </div>
            <div class="content-text1">
                4.3
                本软件仅提供与云存储相关的网络服务，除此之外与网络服务有关的设备（包括但不限于个人电脑、手机及其他与接入互联网或移动网有关的装置）及所需要的费用（包括但不限于为接入互联网而支付的电话费及上网费、为使用移动网而支付的手机费）均应由用户自行承担。
            </div>
            <div class="content-text1">
                4.4 如用户在使用过程中注册资料发生变更，则用户应当及时更新准确的、详细的注册资料。 </div>
            <div class="content-text1">
                4.5用户应当对以其用户账号进行的任何行为承担法律责任，包括但不限于用户上传或散播文本、音频、视频、图片、软件或其它资料，以及本人或他人利用其账号和密码所进行的一切行为。如因用户未保管好其账号和密码而给本软件、第三方造成损失的，用户应当赔偿本软件和第三方因此造成的一切损失，并消除影响。
            </div>
            <div class="content-text1">
                4.6 用户知晓并同意在使用本软件提供的各项服务时，接受本软件和第三方提供的各类信息服务。用户知晓在使用本软件提供的服务时可能有来自第三方的非法内容，包括但不限于威胁、诽谤，用户同意自行承担前述风险。
            </div>
            <div class="content-text1">
                4.7 为了合理地保护未成年人的身心健康、远离有害信息，如用户是未成年人，则须在监护人的指导与监督下使用本软件及接受本软件的服务，否则请立即停止注册或终止使用本软件。
            </div>
            <!-- 5.服务变更、中断或终止 -->
            <h3 class="title-2">5.服务变更、中断或终止</h3>
            <div class="content-text1">
                5.1由于本软件网络服务的特殊性，用户同意本软件有权随时变更、中断或终止部分或全部的网络服务。如变更、中断或终止的网络服务属于免费服务项目，本软件无需提前通知用户，也无需对任何用户或任何第三方承担任何责任。如系付费服务项目，则须提前一周通知用户，并协商解决任何已付未使用或未付款项的事宜。一旦服务取消或终止，用户使用本服务的权利立即终止，且在本服务中储存的任何信息可能无法恢复。
            </div>
            <div class="content-text1">
                5.2 本软件需要定期或不定期地对提供网络服务的平台或相关的设备进行检修或者维护，如因此类情况而造成免费网络服务中断，本软件无需为此承担任何责任。如收费服务在合理时间内中断, 本软件无须为此承担任何责任,
                但应尽可能事先进行通告。 </div>
            <div class="content-text1">
                5.3 本软件对于因为自然灾害、 其他信息服务商故障、相关信息设备和软件故障或者任何不可阻挡因素导致服务中断或终止，不承担任何责任，但应尽可能减少该因素对用户的影响。
            </div>
            <div class="content-text1">
                5.4 对于其他任何非上述因素导致的用户服务中断或终止， 对于免费服务， 本软件不承担任何责任。对于付费服务， 本软件可以在平等协商公平的原则下， 在不超过用户总付费金额的前提下,
                进行赔偿解决。如果用户采取按月付费的模式， 总赔偿金额不得超过服务中断和终止当月付费金额。如果用户采取年付费的模式，总赔偿金额不得超过服务中断和终止当月付费金额。
            </div>
            <div class="content-text1">
                5.5
                为避免服务器资源浪费，如免费用户超过三个月未登录使用本软件服务，本软件保留在未经通知的情况下终止该用户的免费服务并删除该用户在服务器上保存的数据的权利。但本软件在行使该权利前，应尽可能的通知用户以减少该权利行使对用户的影响。
            </div>
            <div class="content-text1">
                5.6
                为避免服务器资源浪费，付费用户如果“超过三个月未及时续费”或者“转化为免费服务”，本软件保留在未经通知的情况下终止该用户的付费服务，并删除该用户在服务器上保存的数据的权利。但本软件在行使该权利前，应尽可能的通知用户以减少该权利行使对用户的影响。
            </div>
            <!-- 6.禁止行为-->
            <h3 class="title-2">6.禁止行为</h3>
            <div class="content-text1">
                6.1 用户不得利用本软件以任何方式传播敏感信息和违反国家法律、政策的信息，包括但不限于下列信息：
                <div>(1) 反对宪法确定的基本原则的；</div>
                <div>(2) 危害国家统一、主权和领土完整的；</div>
                <div>(3) 泄露国家秘密、危害国家安全或者损害国家荣誉和利益的；</div>
                <div>(4) 煽动民族仇恨、民族歧视，破坏民族团结，或者侵害民族风俗、习惯的；</div>
                <div>(5) 宣扬邪教、迷信的；</div>
                <div>(6) 散布谣言，扰乱社会秩序，破坏社会稳定的；</div>
                <div>(7) 宣扬淫秽、赌博、暴力或者教唆犯罪的；</div>
                <div>(8) 侮辱或者诽谤他人，侵害他人合法权益的；</div>
                <div>(9) 危害社会公德或者民族优秀文化传统的；</div>
                <div>(10) 有法律、行政法规和国家规定禁止的其他内容的。</div>
            </div>
            <!-- 7.费用-->
            <h3 class="title-2">7.费用</h3>
            <div class="content-text1">
                7.1 本软件目前是付费使用的，签署试用协议的企业用户，可享1至3个月的免费使用服务。
            </div>
            <div class="content-text1">
                7.2 用户知晓并同意本软件有权根据实际的需要对服务项目收取合理的服务费，如用户不同意本软件收取此项目费用，则用户有权退出使用该项服务或终止使用本软件。
            </div>
            <div class="content-text1">
                7.3 用户理解并同意本软件对新增服务项目有权决定是否收费，用户有权选择接受或拒绝该项目服务。
            </div>
            <div class="content-text1">
                7.4 如用户接受本软件的收费项目，则用户应当按本软件的收费规定支付费用；如用户未支付费用或欠费，则本软件有权不向用户提供此项服务或停止向用户提供此项服务。
            </div>
            <div class="content-text1">
                7.5 本软件有权根据实际需要调整收费项目的资费，用户有权选择接受或拒绝使用。
            </div>


            <!--   8.个人信息-->
            <h3 class="title-2"> 8.个人信息</h3>
            <div class="content-text1">
                8.1 个人信息的收集
                <div>
                    (1)
                    为实现向用户提供本软件产品及/或服务的基本功能，每个用户须授权我们收集、使用手机号、邮箱、密码、企业名称、所在行业、需求场景、公司规模、称呼、职位等必要信息。如用户拒绝提供相应信息，将无法正常使用本软件的产品及/或服务；
                </div>
                <div>
                    (2)
                    在用户使用本软件过程中，经用户授权，本软件还将保存微信信息（微信头像、微信昵称等）、企业微信信息（企业微信头像、昵称等）、钉钉用户信息（钉钉头像、昵称等）等其他第三方平台中保存的用户信息，如用户拒绝提供相应信息，将无法使用本软件提供的相应服务及附加功能；
                </div>
                <div>
                    (3)
                    此外，本软件可能会根据用户的授权从第三方处获取用户的第三方账号信息，并与本软件账号进行绑定，使用户可通过第三方账号直接登录、使用本软件的产品及/或服务。本软件将在用户授权同意的范围内使用用户的相关信息。用户拒绝提供将无法实现本软件服务账号与第三方服务账号的互通。
                </div>
                <div>(4) 同时，本软件可能包含第三方SDK，经过您同意后第三方SDK可直接收集和处理个人信息。</div>
            </div>
            <div class="content-text1">
                8.2 个人信息的使用范围
                <div>
                    (1) 本软件收集和保存的个人信息仅用于个人身份的识别，不作其他用途；
                </div>
                <div>
                    (2) 企业会对本软件收集的个人信息进行数据导入、分析和识别使用，企业对个人信息的保护规则请参见企业的个人信息保护政策；
                </div>
                <div>
                    (3)企业配置的第三方服务提供商会通过本软件获取用户的工作区备注、昵称、邮箱、手机号，第三方服务提供商的个人信息及隐私保护方式请参见第三方服务提供商的个人信息及隐私保护政策及企业与第三方服务提供商签订的相关协议。
                </div>
                <div>(4) 宣扬邪教、迷信的；</div>
                <div>(5) 散布谣言，扰乱社会秩序，破坏社会稳定的；</div>
                <div>(6) 宣扬淫秽、赌博、暴力或者教唆犯罪的；</div>
                <div>(7) 侮辱或者诽谤他人，侵害他人合法权益的；</div>
                <div>(8) 危害社会公德或者民族优秀文化传统的；</div>
                <div>(9) 有法律、行政法规和国家规定禁止的其他内容的。</div>
            </div>
            <div class="content-text1">
                8.3 个人信息的访问与修改使用
                <div>用户可通过访问本软件软件产品或登录网站访问个人信息并对个人信息进行修改。</div>
            </div>
            <div class="content-text1">
                8.4 问题响应
                <div>
                    我们将通过更新本政策、弹窗、页面提示等方式另行向用户说明对应信息的收集目的、范围及使用方式，并为用户提供自主选择同意的方式，且在征得用户明示同意后收集、使用。在此过程中，如果用户有任何疑问、意见或建议的，用户可通过本软件客服或本政策明示的联系方式与我们联系。
                </div>
            </div>

            <!-- 9.隐私保护 -->
            <h3 class="title-2">9.隐私保护</h3>
            <div class="content-text1">
                9.1 保护用户隐私是本软件的一项基本政策，本软件保证不会公开或向第三方提供单个用户的资料以及用户在使用网络服务时存储在本软件中的非公开内容，除非有下列情况出现：
                <div>(1) 有关法律规定或本软件合法服务程序规定；</div>
                <div>(2) 在紧急情况下，为维护用户及公众的权益；</div>
                <div>(3) 为维护本软件的商标权、专利权及其他任何合法权益；</div>
                <div>(4) 用户所属工作区的管理员根据业务需要获取用户的部分个人信息，如用户昵称、邮箱、手机号微信信息等 本软件收集到的第三方平台信息；</div>
                <div>(5) 其他依法需要公开、编辑或透露个人信息的情况。</div>
            </div>
            <!-- 10.知识产权 -->
            <h3 class="title-2">10.知识产权</h3>
            <div class="content-text1">
                10.1
                用户承认许可软件以及在本合同履行过程中所产生的与许可软件有关的源代码、目标代码、文档资料、技术方案以及任何由本软件根据维护与技术支持提供的软件、资料、技术方案的著作权、专利权、商标权等知识产权均归本软件所有。
            </div>
            <div class="content-text1">
                10.2 未经本软件许可，用户不得以任何形式对前述知识产权或其任何部分进行使用、修改等。
            </div>
            <div class="content-text1">
                10.3
                除上述规定外，用户在本软件中上传的任何作品，包括但不限于图片、音乐、视频的知识产权归用户所有（“用户作品”）或第三方所有（“第三方作品”）。用户保证对前述用户作品享有完全知识产权；如系第三方作品，用户应当获得第三方的许可或未获得第三方许可但有权合法使用第三方作品。
            </div>
            <div class="content-text1">
                10.4
                除法律法规另有规定外，未获得第三方作品所有人的事先许可，用户不得以任何方式使用第三方作品。如果本软件收到任何第三方作品所有人或其合法代表发给本软件的合理通知后，本软件有权移除相应侵权作品；用户也有权对前述侵权通知提交不存在侵权行为的声明，声明应当包括不存在侵权行为的初步证据。
            </div>

            <!-- 11.知识产权侵权 -->
            <h3 class="title-2">11.知识产权侵权</h3>
            <div class="content-text1">
                11.1 用户保证，在本软件中储存的用户作品或第三方作品不侵犯任何第三方知识产权，包括但不限于专利权、商标权、版权和商业秘密等。
            </div>

            <div class="content-text1">
                11.2
                若用户在本软件中储存的用户作品或第三方作品导致第三方主张前述作品侵犯其知识产权，用户应当负责处理前述第三方的权利主张，承担由此招致的全部费用，包括但不限于律师费和侵权赔偿，并保证本软件不会因此而遭受任何损失。用户同意，若发生上述侵权行为，本软件有权移除上述侵权作品。
            </div>
            <!-- 12.免责和不保证 -->
            <h3 class="title-2">12.免责和不保证</h3>
            <div class="content-text1">
                12.1 在适用法允许的最大范围内，本软件在任何情况下不对用户因使用或不能使用本软件提供的服务所发生的特殊的、意外的、直接或间接的损失承担任何责任。
            </div>

            <div class="content-text1">
                12.2 本软件不对提供的服务作任何明示或暗示的保证，包括但不限于不保证本软件提供的服务一定能满足用户的要求，也不保证服务是否会中断、及时性、安全性。
            </div>
            <!-- 13.违约责任 -->
            <h3 class="title-2">13.违约责任</h3>
            <div class="content-text1">
                13.1 用户因违反国家法律法规或本条款的约定而给本软件造成损失的，用户应赔偿本软件因此而遭受的一切损失，并消除影响。
            </div>

            <div class="content-text1">
                13.2
                由于企业不慎操作导致相关用户以及企业的信息泄露，对用户以及企业造成直接或者间接损失。企业将承担相关责任，针对用户以及企业的信息侵权行为将在10个工作日内改正，对用户导致相关损失将由我们承担，赔偿上限不超过用户在本企业消费金额3倍。相关分歧涉及诉讼或者仲裁，约定在苏州仲裁委员会或者苏州人民法院诉讼。
            </div>
            <!--  14.不可抗力 -->
            <h3 class="title-2"> 14.不可抗力</h3>
            <div class="content-text1">
                14.1 由于不可抗力事件，致使用户不能使用本软件提供的服务时，本软件不对用户的任何损失承担责任。不可抗力事件包括但不限于：
                <div>（1）自然灾害，如台风、洪水、冰雹等；</div>
                <div>（2）政府行为，如征收、征用等；</div>
                <div>（3）社会异常事件，如罢工、骚乱等；</div>
                <div>（4） 计算机病毒或黑客攻击、互联网络、通信线路等原因造成服务中断。</div>
            </div>
            <!--  15.生效和期限 -->
            <h3 class="title-2">15.生效和期限</h3>
            <div class="content-text1">
                15.1 本条款自用户授权我公司开通企业管理员账号后开始生效。
            </div>
            <div class="content-text1">
                15.2 用户注销其用户名或本软件终止您的用户资格时，用户使用权利终止，此后第10、11、12、13、16、17条仍维持有效。
            </div>

            <!-- 16.法律适用和争议解决 -->
            <h3 class="title-2">16.法律适用和争议解决</h3>
            <div class="content-text1">
                16.1 本服务条款应受中华人民共和国法律解释和管辖，并不考虑法律冲突。
            </div>
            <div class="content-text1">
                16.2 有关本条款的任何争议应由双方秉承善意友好协商解决。若三十日内协商不成，双方同意将争议向苏州市太仓市人民法院提起诉讼。诉讼费用应由仲裁败诉方承担。
            </div>

            <!--17.一般条款 -->
            <h3 class="title-2">17.一般条款</h3>
            <div class="content-text1">
                17.1 用户和本软件在履行本条款的过程中，应遵从所有适用的法律。
            </div>
            <div class="content-text1">
                17.2 本软件有权通过在本软件上发布通知或发送电子邮件等方式向用户发送通知、广告等。
            </div>
            <div class="content-text1">
                17.3 本条款所载任何内容不应被解释为在用户和本软件之间创设合资、合伙、代理或任何其它本条款目的以外的关系。
            </div>
            <div class="content-text1">
                17.4 用户理解并同意本软件有权根据实际需要修改本条款。如果您不同意变更，则用户有权退出本软件，终止服务关系；如用户继续使用本软件提供的服务，则表示用户同意本条款的修改。
            </div>
            <div class="content-text1">
                17.5 若本条款中部分条款因任何原因而被认定无效，此种无效条款并不影响其他条款的有效性，且此种无效条款应自始视为不存在。
            </div>
            <div class="content-text1">
                17.6 本软件有权转让其在本条款下的任何权利或义务。
            </div>
            <div class="content-text1">
                17.7 本条款中所有标题仅为方便阅读所设置，并不影响本条款任何部分的含义或解释。
            </div>

            <!--一、原则 -->
            <h3 class="title-2">一、原则</h3>
            <div class="content-text1">
                为了保障您（即「用户」）的使用体验、维护良好的平台形象，本软件确定以下内容为平台使用规范。当您成功注册本软件账号，意味着您完全了解并接受此规范约束。
            </div>

            <!--二、相关条款 -->
            <h3 class="title-2">二、相关条款</h3>
            <div class="content-text1">
                使用本软件平台的服务，您必须阅读并遵守本软件的《服务条款》。本规范是基于此条款进行的解释和说明，以便您能够更加顺利地使用本软件，而不是修改或变更此条款中的任何内容。
            </div>

            <!--三、使用规范-->
            <h3 class="title-2">三、使用规范</h3>
            <div class="content-text1">
                <div>
                    <li>账号开通规范</li>
                    <li>绑定的邮箱地址要求真实有效，并通过该邮箱激活本软件账号。</li>
                    <li>账号昵称、头像等资料涉及色情、暴力等违法违规内容的，将被冻结账号。</li>
                    <li>通过机器方式批量、暴力创建账号的行为将导致账号永久冻结。</li>
                    <li>通过公开链接储存、传播色情、暴力等违法违规内容的，将封禁工作区。</li>
                    <li>账号行为规范</li>
                </div>
                <div>
                    因贷款类表单一直接到高频且大量举报，本软件针对此类表单设置了专门的监控系统进行严格管控。如果您需要在系统中发放贷款，
                    请先联系<a style="color: blueviolet;">colorx_robot@aliyun.com</a>
                    提供经营贷款业务的资质，通过审核后，方能正常使用本软件。
                    鉴于以下行为严重影响用户体验，并可能给其他用户及平台带来损害，一经发现将直接冻结账号。
                </div>
                <div class="content-text1">
                    常见违规行为：
                    <div> -销售假冒伪劣产品、进行刷单返现业务</div>
                    <div>-非银行或拥有从业资质的机构进行金融业务：借贷、银行卡或信用卡办理等</div>
                    <div>-传播色情、非法性病治疗营销信息，售卖非法性用品、性药品、性保健品</div>
                    <div>-包含钓鱼信息、收集敏感账号密码：支付宝密码、手机服务密码、QQ 密码、银行卡密码等</div>
                    <div>-非法集资或传销、假冒官网发布信息或售卖产品</div>
                    <div>-违规使用附件上传涉及色情、暴力等违规违法内容</div>
                    <div>-使用非官方认可途径绕过附件用量限制造成本软件损失</div>
                    <div>-违反法律法规：散布谣言；传播色情、暴力、赌博或迷信等信息</div>
                </div>
                <div class="content-text1">
                    1.举报和处罚规范
                    <div> 所有人可通过表单填写页面右下角「举报」按钮向本软件举报以上违规行为。若举报理由不充分，本软件毋须处理；处理结果不会通知举报人和被举报账号。
                        本软件不会在处理前对确定违规或因大量举报涉嫌违规的用户发出警告，而是直接采取以下措施：</div>
                    <div>-永久冻结账号，账号不予登陆，数据不予导出。</div>
                    <div>-限制已创建的表单的访问。</div>
                    <div>-付费用户不予退还任何费用。</div>
                    <div>-如果掌握了足够的证据，本软件还将协助国家公安机关严厉打击违法犯罪行为。</div>
                </div>
                <div class="content-text1">
                    2.申诉规范
                    <div>如果您对基于本规范做出的处理有异议，可向本软件申诉并提供真实的信息和充分的理由，如有需要，本软件将联系您获取更多信息。</div>
                    <div>若提交的申诉信息不真实或理由不充分，本软件毋须处理。申述处理结果为最终结果，同一申诉事件的重复提交，本软件不再受理。</div>
                    <div>对于借贷等敏感金融业务，即使提供相关资质证明，在过多人举报的情况下，账号仍将保持冻结状态。</div>
                </div>
            </div>
            <!--四、免责申明-->
            <h3 class="title-2">四、免责申明</h3>
            <div class="content-text1">
                本软件不提供任何种类的明示或暗示担保或条件，您对账号的使用行为必须自行承担相应风险。
            </div>

            <!--五、规范修改-->
            <h3 class="title-2">五、规范修改</h3>
            <div class="content-text1">
                此规范将根据相关法律和业务发展的需要保持修改，本软件将会直接在网站上公布修改之后的规范内容，此公布行为视作已通知您修改内容。您应反复查看以便获得最新信息。
            </div>
            <!-- 结束 -->
        </div>


    </div>
</template>
<script>
import { cryptoAes } from '@/utils/index'
import { sendCode, getCodeApi } from '@/api/login'
import { Message } from 'element-ui'
import Cookies from 'js-cookie'
import resize from '@/utils/resize'
export default {
    name: 'login',
    mixins: [resize],
    data() {
        const validatePassword = (rule, value, callback) => {

        }
        return {

        }
    },
    created() {
    },
    methods: {
    }
}
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
.content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.content-text1 {
    background-color: #F2F6FC;
    padding-top: 10px;
    width: 700px;
    font-size: 12px;
}
</style>
  